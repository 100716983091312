@charset "UTF-8";

#tapeta,
#tapeta_sg,
#tapeta_kat,
#tapeta_art {
	height: 0;
}

.adslot {
	$adSlot: &;

	&--only-desktop {
		display: none;

		@media (min-width: 996px) {
			display: block;
		}
	}

	&--only-mobile {
		display: block;

		@media (min-width: 996px) {
			display: none;
		}
	}

	&__ad-container {
		text-align: center;
		min-width: 300px;
		//min-height: 324px; chwiliowo cofam bo rozwala belkę expand 

		/*
		position: relative;
		z-index: 2;
		*/

		.adform-interscroller-wrapper {
			z-index: 999999;
		}

		// wrzucony ograniczony dla bilbordow margin bo na intextach sticky margin powoduje skakanie
		&[data-name^="ART_Billboard"] {
			margin: 18px 0;
		}

		//Technicznie, jak działa SSR to zaślepki generują się od razu jednak zostawiam ten kawałek kodu, aby uniknąć skoku CLS
		&[data-name^="KAT_Billboard"]:not([data-name$="partner"]) {
			min-height: 324px;
		}

		&[data-name^="SG_Billboard"]:not([data-name$="partner"]) {
			min-height: 324px;
		}

		/*
		//toybox #83; szare tlo za reklamami intext
		&[data-name^="ART_intext_1"]{
			background: #e7e7e7;
            padding-bottom: 45px;
            padding-top: 20px;
		}
		*/
	}

	&--tapeta {
		position: relative;
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		&>.adslot__ad-label {
			position: absolute;
			right: 20px;
			top: 0;
		}

		&>.adslot__ad-container {
			&::before {
				display: none;
			}
		}
	}

	@mixin adSlot-placeholder {
		display: inline-block;

		/* musi tak byc bo inaczej element dostaje wysokość 22px */
		// margin-top: 10px;
		// margin-bottom: 10px;
		.adslot__ad-label {
			display: inline-block;
		}

		.adslot__ad-wrapper--gray {
			background: #E3E3E3;

			.adslot__ad-label {
				display: block;
			}
		}
	}

	@mixin adSlot-fixed {
		.adslot__ad-container {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:not([style]) {
				min-height: 250px;
			}

			&>div {
				z-index: 2;
			}

			&::before {
				content: "";
				background: #b3b3b3 url(/img/natemat/logo_simple.svg) no-repeat center center;
				background-size: auto 150px;
				opacity: 0.195;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				position: absolute;
				z-index: 0;

				-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
				-o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
				-webkit-filter: grayscale(100%);
				filter: gray;
			}

			&--mamadu::after {
				background-image: url(/img/mamadu/logo_simple.svg);
				background-color: #aaa
			}

			&--innpoland::after {
				background-image: url(/img/innpoland/logo_simple.svg);
				background-color: #777
			}

			&--aszdziennik::after {
				background-image: url(/img/aszdziennik/logo_simple.svg);
				background-color: #333;
			}
		}
	}

	&__ad-wrapper {
		display: block;
		text-align: left;
		position: relative;

		&--block {
			display: block !important;
		}

		&.adslot--active {
			@include adSlot-placeholder;
		}

		&--gray {
			width: auto;
			margin-left: -20px;
			margin-right: -20px;

			.adslot__ad-label {
				width: 300px;
				margin: 0 auto;
				padding: 10px 0;
				font-size: 17px;
				color: #68686c;
			}
		}

		&--fixed {
			@include adSlot-fixed;
		}
	}

	&__ad-label {
		font-size: 11px;
		display: none;
		color: #A5A5A5;
		background: #E3E3E3;
		padding: 0 6px;
		margin: 0 auto;
		line-height: 20px;
	}

	&__ad-container {
		text-align: center;
		margin: 0 auto;

		// #1548
		.innpoland--embed-feed__top {
			max-width: 1200px;
			margin: 0 auto;
		}

		//Placeholder
		--placeholder-desktop-width: 0px;
		--placeholder-desktop-height: 0px;

		--placeholder-mobile-width: 0px;
		--placeholder-mobile-height: 0px;

		min-width: var(--placeholder-mobile-width);
		min-height: var(--placeholder-mobile-height);

		@media (min-width: 996px) {
			min-width: var(--placeholder-desktop-width);
			min-height: var(--placeholder-desktop-height);
		}
	}

	&__ad-content {
		z-index: 1;
	}

	&--placeholder-desktop {
		@media(min-width: 996px) {
			@include adSlot-placeholder;
			@include adSlot-fixed;
		}
	}

	&--placeholder-mobile {
		@media(max-width: 996px) {
			@include adSlot-placeholder;
			@include adSlot-fixed;
		}
	}

}

.screening_wallpaper_inner {
	@extend .adslot--tapeta;
}